<template>
    <section class="pa-4 formular" v-if="document_ready">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>
        <AdvancedMenu :items="advancedMenu" :handle="handleClick" v-if="checkPrivileg && edit"/>

        <!--FORM-->
        <v-container v-if="checkPrivileg" fluid>
            <div v-if="button_menu">
                <v-btn class="gold lighten-1 white--text mr-1" depressed small @click="newCustomer">
                    <v-icon left>mdi-account</v-icon>
                    <span>{{ $t('products_agreements.dialog.new_client') }}</span>
                </v-btn>
                <v-btn class="gold lighten-1 white--text mr-1" depressed small
                       @click="openSelectDialog('customers_list_select')">
                    <v-icon left>mdi-account-group</v-icon>
                    <span>{{ $t('products_agreements.dialog.existing_clients') }}</span>
                </v-btn>
            </div>

            <v-form v-if="agreementForm" @submit.prevent="submit" id="form" ref="form" class="formular">
                <!--PRODUCT-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('products_agreements.dialog.product_data') }}
                    </v-col>

                    <v-alert v-if="info_text !== null"
                             dense text type="info" color="gold" icon="mdi-cash-100" class="my-0 mb-1 mx-3">
                        <span v-html="info_text"/>
                    </v-alert>

                    <!--ZLATA RYBKA-->
                    <v-col cols="12" v-if="product === 'productgoldt1'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'child_name_surname')"
                                              v-model="agreement_data.product.childName"

                                              v-validate="'required'"
                                              data-vv-name="child_name"
                                              :error-messages="errors.collect('child_name')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'child_identification_number')"
                                              v-model="agreement_data.product.childPersonalId"

                                              v-validate="'required|child_born_limit'"
                                              data-vv-name="child_personal_id"
                                              :error-messages="errors.collect('child_personal_id')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'target_price')" :prefix="currency"
                                              v-model="agreement_data.product.productTargetPrice"

                                              v-validate="'required|min_value:10000|package_t1t2'"
                                              data-vv-name="product_target_price"
                                              :error-messages="errors.collect('product_target_price')"
                                              @keyup="advancedPaymentForm"
                                              @blur="advancedPaymentForm"
                                              @change="advancedPaymentForm"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t(path_table_key + 'advance')"
                                          item-text="alias"
                                          item-value="id"
                                          :items="product_combo"
                                          v-model="agreement_data.product.productFee"
                                          @change="advancedPaymentForm"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'advance_price')"
                                              v-model="agreement_data.product.productAdvancePayment"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'monthly_payment')" :prefix="currency"
                                              v-model="agreement_data.product.monthlyPayment"

                                              v-validate="'required'"
                                              data-vv-name="monthly_payment"
                                              :error-messages="errors.collect('monthly_payment')"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATY DOCHODOK-->
                    <v-col cols="12" v-if="product === 'productgoldt2'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'target_price')" :prefix="currency"
                                              v-model="agreement_data.product.productTargetPrice"

                                              v-validate="'required|min_value:10000|package_t1t2'"
                                              data-vv-name="product_target_price"
                                              :error-messages="errors.collect('product_target_price')"
                                              @keyup="advancedPaymentForm"
                                              @blur="advancedPaymentForm"
                                              @change="advancedPaymentForm"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t(path_table_key + 'advance')"
                                          item-text="alias"
                                          item-value="id"
                                          :items="product_combo"
                                          v-model="agreement_data.product.productFee"
                                          @change="advancedPaymentForm"
                                />
                            </v-col>

                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'advance_price')"
                                              v-model="agreement_data.product.productAdvancePayment"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'monthly_payment')" :prefix="currency"
                                              v-model="agreement_data.product.monthlyPayment"

                                              v-validate="'required'"
                                              data-vv-name="monthly_payment"
                                              :error-messages="errors.collect('monthly_payment')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATY POKLAD-->
                    <v-col cols="12" v-if="product === 'productgoldt3'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency" readonly ref="t3"
                                              :label="$t(path_table_key + 'investment_target')"
                                              v-model="agreement_data.product.productInvestmentTarget"

                                              v-validate="'required|t3|package_t3'"
                                              data-vv-name="product_selection"
                                              :error-messages="errors.collect('product_selection')"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t(path_table_key + 'fee')"
                                          item-text="alias"
                                          item-value="value"
                                          :items="product_combo_t3"
                                          :disabled="product_combo_t3.length === 1"
                                          v-model="agreement_data.product.productFee"
                                          @change="update_t3"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'fee_price')"
                                              v-model="agreement_data.product.productAdvancePayment" disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'target_price_t3')"
                                              v-model="agreement_data.product.productTargetPrice" disabled
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="py-3"></v-divider>
                        <v-row>
                            <v-col v-bind="col_grams"
                                   v-for="(item, i) in agreement_data.product.productSelection"
                                   :key="i">
                                <v-row>
                                    <v-col cols="12" class="pr-0">
                                        <v-text-field outlined dense color="gold" readonly class="gram"
                                                      :hint="'x ' + item.price.toFixed(2) + ' € = ' + (item.count * item.price).toFixed(2) + ' €'"
                                                      :label="item.name" persistent-hint
                                                      :placeholder="$t(path_table_key + 'multiplier')"
                                                      v-model="agreement_data.product.productSelection[i].count"
                                                      @change="update_t3"
                                        >
                                            <template v-slot:prepend-inner>
                                                <v-btn icon small @click="decrease(i)"
                                                       :disabled="agreement_data.product.productSelection[i].count <= 0">
                                                    <v-icon color="gold">mdi-minus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:append>
                                                <v-btn icon small @click="increase(i)">
                                                    <v-icon color="gold">mdi-plus-circle</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATA MINCA-->
                    <v-col cols="12" v-if="product === 'productgoldt5'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'target_price')"
                                              v-model="agreement_data.product.productTargetPrice"
                                              :disabled="true"
                                ></v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'fee')"
                                              v-model="agreement_data.product.productAdvancePayment"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox v-if="canSellProductT5ForFree"
                                            class="pt-0 mt-2"
                                            v-model="agreement_data.forFree"
                                            :label="$t(path_table_key + 'free_of_charge')"
                                            hide-details
                                            @change="productT5ForFree"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'monthly_payment')"
                                              v-model="agreement_data.product.monthlyPayment"

                                              v-validate="'required'"
                                              data-vv-name="monthly_payment"
                                              :error-messages="errors.collect('monthly_payment')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-bind="col_default" v-if="product_t1_child">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'child_name_surname')"
                                              v-model="agreement_data.product.childName"

                                              v-validate="'required'"
                                              data-vv-name="child_name"
                                              :error-messages="errors.collect('child_name')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default" v-if="product_t1_child">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'child_identification_number')"
                                              v-model="agreement_data.product.childPersonalId"

                                              v-validate="'required|child_born_limit'"
                                              data-vv-name="child_personal_id"
                                              :error-messages="errors.collect('child_personal_id')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox class="pt-0 mt-2" @click="resetChildData"
                                            v-model="product_t1_child"
                                            :label="$t('products_agreements.dialog.child')"
                                            hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATA ISTOTA-->
                    <v-col cols="12" v-if="product === 'productgoldt4'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'target_price')"
                                              v-model="agreement_data.product.productTargetPrice"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold" :prefix="currency"
                                              :label="$t(path_table_key + 'fee')"
                                              v-model="agreement_data.product.productAdvancePayment"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t(path_table_key + 'monthly_payment')" :prefix="currency"
                                              v-model="agreement_data.product.monthlyPayment"

                                              v-validate="'required'"
                                              data-vv-name="monthly_payment"
                                              :error-messages="errors.collect('monthly_payment')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox class="pt-0 mt-2"
                                            v-model="agreement_data.product.productMonthlyCharge"
                                            :label="$t(path_table_key + 'repayments')"
                                            hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default" v-if="edit">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.id')"
                                              v-model="agreement_data.id"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t('users.dialog.type')"
                                          item-text="name"
                                          item-value="id"
                                          :items="person_types"
                                          v-model="agreement_data.customer.type"
                                          @change="checkType()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.title')"
                                              v-model="agreement_data.customer.degree"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.name')"
                                              v-model="agreement_data.customer.name"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="name"
                                              data-vv-name="user_name"
                                              :error-messages="errors.collect('user_name')"

                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.surname')"
                                              v-model="agreement_data.customer.surname"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="surname"
                                              data-vv-name="user_surname"
                                              :error-messages="errors.collect('user_surname')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.private_email')"
                                              v-model="agreement_data.customer.email"
                                              autocomplete="off"
                                              :disabled="agreement_data.customer.id !== null"
                                              v-validate="'required|email|checkExistingEmail'"
                                              data-vv-name="user_private_email"
                                              :error-messages="errors.collect('user_private_email')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.tel')"
                                              v-model="agreement_data.customer.phone"

                                              v-validate="'required'"
                                              data-vv-name="user_tel"
                                              :error-messages="errors.collect('user_tel')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-menu
                            ref="menu_date_of_birth"
                            v-model="modal_date_of_birth"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="agreement_data.customer.dateOfBirth"
                                    :label="$t('users.dialog.date_of_birth')"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    outlined dense
                                    @click="$validator.validate('user_date_of_birth')"
                                    @focus="$validator.validate('user_date_of_birth')"
                                    color="gold"
                                    v-validate="'required|bornLimit'"
                                    data-vv-name="user_date_of_birth"
                                    :error-messages="errors.collect('user_date_of_birth')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="agreement_data.customer.dateOfBirth"
                                @change="saveDateOfBirth"
                                locale="sk"
                                color="gold"
                            ></v-date-picker>
                        </v-menu>


                        <!--                        <v-dialog-->
                        <!--                            ref="dialog_date_of_birth"-->
                        <!--                            v-model="modal_date_of_birth"-->
                        <!--                            :return-value.sync="agreement_data.customer.dateOfBirth"-->
                        <!--                            persistent-->
                        <!--                            width="290px"-->
                        <!--                        >-->
                        <!--                            <template v-slot:activator="{ on, attrs }">-->
                        <!--                                <v-text-field-->
                        <!--                                    v-model="agreement_data.customer.dateOfBirth"-->
                        <!--                                    :label="$t('users.dialog.date_of_birth')"-->
                        <!--                                    prepend-inner-icon="mdi-calendar"-->
                        <!--                                    readonly-->
                        <!--                                    v-bind="attrs"-->
                        <!--                                    v-on="on"-->
                        <!--                                    outlined dense-->
                        <!--                                    @click="$validator.validate('user_date_of_birth')"-->
                        <!--                                    @focus="$validator.validate('user_date_of_birth')"-->
                        <!--                                    color="gold"-->

                        <!--                                    v-validate="'required|bornLimit'"-->
                        <!--                                    data-vv-name="user_date_of_birth"-->
                        <!--                                    :error-messages="errors.collect('user_date_of_birth')"-->
                        <!--                                >-->
                        <!--                                    <template v-slot:prepend>-->
                        <!--                                        <v-icon x-small color="red">mdi-asterisk</v-icon>-->
                        <!--                                    </template>-->
                        <!--                                </v-text-field>-->
                        <!--                            </template>-->
                        <!--                            <v-date-picker-->
                        <!--                                v-model="agreement_data.customer.dateOfBirth"-->
                        <!--                                scrollable-->
                        <!--                                :first-day-of-week="1"-->
                        <!--                                locale="sk"-->
                        <!--                                color="gold"-->
                        <!--                            >-->
                        <!--                                <v-spacer></v-spacer>-->
                        <!--                                <v-btn-->
                        <!--                                    text-->
                        <!--                                    color="gold"-->
                        <!--                                    @click="modal_date_of_birth = false"-->
                        <!--                                >-->
                        <!--                                    {{ $t('common.cancel') }}-->
                        <!--                                </v-btn>-->
                        <!--                                <v-btn-->
                        <!--                                    text-->
                        <!--                                    color="gold"-->
                        <!--                                    @click="$refs.dialog_date_of_birth.save(agreement_data.customer.dateOfBirth);"-->
                        <!--                                >-->
                        <!--                                    {{ $t('common.ok') }}-->
                        <!--                                </v-btn>-->
                        <!--                            </v-date-picker>-->
                        <!--                        </v-dialog>-->
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_id_number-input"
                                      :label="$t('users.dialog.id_number')"
                                      v-model="agreement_data.customer.personalID"
                                      v-validate="'required'"
                                      data-vv-name="user_id_number"
                                      :error-messages="errors.collect('user_id_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!--PERSON DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="agreement_data.customer.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        />
                    </v-col>
                </v-row>
                <!--COMPANY DATA-->
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_company_name-input"
                                      :label="$t('users.dialog.company_name')"
                                      v-model="agreement_data.customer.companyName"

                                      v-validate="'required'"
                                      data-vv-name="user_company_name"
                                      :error-messages="errors.collect('user_company_name')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ico-input"
                                      :label="$t('users.dialog.ico')"
                                      v-model="agreement_data.customer.companyId"

                                      v-validate="'required'"
                                      data-vv-name="ico"
                                      :error-messages="errors.collect('ico')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="agreement_data.customer.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ic_dph-input"
                                      :label="$t('users.dialog.ic_dph')"
                                      v-model="agreement_data.customer.vatId"
                        />
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_street_and_number-input"
                                      :label="$t('users.dialog.street_and_number')"
                                      v-model="agreement_data.customer.mainAddress.street"
                                      @keyup="$validator.validate('user_street_and_number')"

                                      v-validate="'required'"
                                      data-vv-name="user_street_and_number"
                                      :error-messages="errors.collect('user_street_and_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.city')"
                                      v-model="agreement_data.customer.mainAddress.city"
                                      @keyup="$validator.validate('user_city')"

                                      v-validate="'required'"
                                      data-vv-name="user_city"
                                      :error-messages="errors.collect('user_city')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.psc')"
                                      v-model="agreement_data.customer.mainAddress.zip"
                                      @keyup="$validator.validate('user_psc')"

                                      v-validate="'required'"
                                      data-vv-name="user_psc"
                                      :error-messages="errors.collect('user_psc')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('users.dialog.country')"
                                  :items="getCountries"
                                  item-text="name"
                                  v-model="agreement_data.customer.mainAddress.country"
                        />
                    </v-col>
                </v-row>

                <!--AGREEMENT DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('products_agreements.dialog.agreement_data') }}
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-dialog
                            ref="dialog_start_collaboration"
                            v-model="modal_start_collaboration"
                            :return-value.sync="agreement_data.signatureDate"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="agreement_data.signatureDate"
                                    :label="$t('products_agreements.dialog.signature_date')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    color="gold"
                                    :disabled="checkPrivileg && !edit"
                                />
                            </template>
                            <v-date-picker
                                v-model="agreement_data.signatureDate"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="modal_start_collaboration = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.dialog_start_collaboration.save(agreement_data.signatureDate)"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.iban')"
                                      v-model="agreement_data.customer.bankAccount"
                        />
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('products_agreements.table_keys.deposit')"
                                  item-text="name"
                                  item-value="id"
                                  :items="delivery_types"
                                  v-model="agreement_data.delivery"
                        />
                    </v-col>
                </v-row>
                <!--SELLER-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('products_agreements.dialog.seller') }}
                    </v-col>
                    <v-col cols="12">
                        <v-card flat outlined>
                            <v-card-text>
                            <span class="headline">
                                        {{ agreement_data.seller.name + " " + agreement_data.seller.surname }}
                                        <span class="gold--text" v-if="agreement_data.seller.companyName !== ''">
                                            - {{ agreement_data.seller.companyName }}
                                        </span>
                                    </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="checkPrivileg" cols="12">
                        <v-btn class="gold lighten-1 white--text" depressed small
                               @click="openSelectDialog('sellers_list_select')">
                            <v-icon left>mdi-account-tie</v-icon>
                            <span>{{ $t('users.dialog.change_seller') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <!--DIALOGS-->
            <v-dialog v-model="dialog_select" persistent scrollable fullscreen>
                <v-card flat v-if="dialog_select">
                    <v-toolbar fixed dark dense color="gold" style="z-index: 10; flex: unset;">
                        <v-btn icon dark @click="dialog_select = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                            <span>{{ $t('products_agreements.dialog.selector_title.' + selectList) }}</span>
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pt-0 px-0">
                        <DataSelect :data_view_name="selectList"/>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_transfer_grams" max-width="480" persistent>
                <v-card v-if="dialog_transfer_grams">
                    <v-toolbar dark dense color="gold">
                        <v-toolbar-title>
                            <v-icon left>mdi-transfer</v-icon>
                            {{ $t('advanced_menu.transfer_grams') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-form @submit.prevent="submit" id="form_grams" ref="form_grams">
                        <v-card-text class="py-3">
                            <v-row>
                                <v-col cols="12" align="center">
                                    {{ $t('products_agreements.dialog.agreement_amount') }}:
                                    <strong>{{ card_item.coins }}</strong>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field outlined dense color="gold"
                                                  v-model="reallocate_grams"

                                                  v-validate="'required|double|max_gold'"
                                                  data-vv-name="reallocate_grams"
                                                  :error-messages="errors.collect('reallocate_grams')"
                                    >
                                        <template v-slot:prepend>
                                            <span>{{ $t('products_agreements.dialog.amount') }}</span>
                                            <v-icon right x-small color="red">mdi-asterisk</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed small color="gold" dark @click="click_reallocateGrams">
                                {{ $t('products_agreements.dialog.transfer') }}
                            </v-btn>
                            <v-btn depressed small @click="dialog_transfer_grams = false">
                                {{ $t('common.cancel') }}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_gold_purchase" max-width="960" persistent scrollable>
                <v-card v-if="dialog_gold_purchase">
                    <v-toolbar dark dense color="gold" style="z-index: 2">
                        <v-toolbar-title>
                            <v-icon left>mdi-gold</v-icon>
                            {{ $t('advanced_menu.gold_purchase') }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pa-2">
                        <v-container fluid>
                            <v-row v-if="canTransferGoldPurchases">
                                <v-col class="pa-0" cols="12" v-for="goldPurchase in getGoldPurchases"
                                       :key="goldPurchase.id"
                                       @click="openSelectDialog('agreements_list_select', goldPurchase)">
                                    <Card card="AgreementGoldPurchaseCard" :item="goldPurchase"/>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col class="pa-0" cols="12" v-for="goldPurchase in getGoldPurchases"
                                       :key="goldPurchase.id">
                                    <Card card="AgreementGoldPurchaseCard" :item="goldPurchase"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed small @click="dialog_gold_purchase = false">
                            {{ $t('common.cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_agreement_confirm" max-width="800" persistent scrollable>
                <v-card v-if="dialog_agreement_confirm">
                    <v-toolbar dark dense color="gold" style="z-index: 2">
                        <v-toolbar-title>
                            <v-icon left>mdi-gold</v-icon>
                            {{ $t('products_agreements.dialog.agreement_confirm') }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-card-text class="pa-2">
                        <Card card="AgreementGoldPurchaseCard" :item="agreement_confirm.purchase"/>
                    </v-card-text>

                    <v-card-text align="center" class="py-0">
                        <v-icon color="gold" size="64">mdi-arrow-down-bold</v-icon>
                    </v-card-text>
                    <v-card-text align="center" class="pb-0">
                        <span class="green white--text rounded px-4 font-weight-light text-uppercase headline">{{
                                $t('products_agreements.dialog.target_agreement')
                            }}</span>
                    </v-card-text>


                    <v-card-text class="pa-2">
                        <Card card="AgreementTransferCard" :item="agreement_confirm.transfer_agreement"/>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="gold" dark depressed small @click="click_transferGoldPurchase">
                            {{ $t('common.ok') }}
                        </v-btn>
                        <v-btn depressed small @click="dialog_agreement_confirm = false">
                            {{ $t('common.cancel') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--BOTTOM BUTTONS-->
            <v-container fluid v-if="agreementForm">
                <v-row align="center" justify="center" class="mt-4">
                    <v-col cols="12" align="center" v-if="checkPrivileg && !edit">
                        <v-btn class="gold lighten-1 white--text mr-1" depressed small @click="submit('createItem')">
                            {{ $t('products_agreements.dialog.agreement_accept') }}
                        </v-btn>
                        <v-btn class="white lighten-1 gold--text mr-1" depressed small outlined
                               @click="callbackFromCreateMode('createItemCancel')">{{ $t('common.cancel') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>

        </v-container>

        <!--READER-->
        <v-container v-else fluid>
            <div v-if="document_ready">
                <!--PRODUCT-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('products_agreements.dialog.product_data') }}
                    </v-col>
                    <!--ZLATA RYBKA-->
                    <v-col cols="12" v-if="product === 'productgoldt1'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'child_name_surname') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ g_isDefined(agreement_data.product.childName) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'child_identification_number') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ g_isDefined(agreement_data.product.childPersonalId) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'target_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productTargetPrice) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'advance')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ product_combo.find(element => element.value === advancePayment).alias }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'advance_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productAdvancePayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'monthly_payment') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.monthlyPayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATY DOCHODOK-->
                    <v-col cols="12" v-if="product === 'productgoldt2'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'target_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productTargetPrice) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'advance')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ product_combo.find(element => element.value === advancePayment).alias }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'advance_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productAdvancePayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'monthly_payment') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ g_isDefined(agreement_data.product.monthlyPayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATY POKLAD-->
                    <v-col cols="12" v-if="product === 'productgoldt3'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'investment_target') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }} {{
                                            g_isDefined(parseFloat(agreement_data.product.productInvestmentTarget).toFixed(2))
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'fee')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ product_combo_t3.find(element => element.id === advancePaymentT3).alias }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'fee_price')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }} {{
                                            g_isDefined(parseFloat(agreement_data.product.productAdvancePayment).toFixed(2))
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'target_price_t3') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ currency }} {{
                                            g_isDefined(parseFloat(agreement_data.product.productTargetPrice).toFixed(2))
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row>
                            <v-col v-bind="col_default"
                                   v-for="(item, i) in agreement_data.product.productSelection"
                                   :key="i">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{ item.name }}</v-col>
                                    <v-col cols="12" class="body-1 py-0"
                                           :class="{'font-weight-bold gold--text' : agreement_data.product.productSelection[i].count}">
                                        {{ agreement_data.product.productSelection[i].count }}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" class="caption pa-0 ma-0 pl-3"
                                           :class="{'gold--text' : agreement_data.product.productSelection[i].count}">x
                                        €{{ item.price.toFixed(2) + ' = €' + (item.count * item.price).toFixed(2) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATA MINCA-->
                    <v-col cols="12" v-if="product === 'productgoldt5'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'target_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productTargetPrice) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'fee')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productAdvancePayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox class="pt-0 mt-2"
                                            v-model="agreement_data.forFree"
                                            :label="$t(path_table_key + 'free_of_charge')"
                                            hide-details disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'monthly_payment') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.monthlyPayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-bind="col_default" v-if="product_t5_child">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'child_name_surname') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ g_isDefined(agreement_data.product.childName) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default" v-if="product_t5_child">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'child_identification_number') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ g_isDefined(agreement_data.product.childPersonalId) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox class="pt-0 mt-2"
                                            v-model="product_t5_child"
                                            :label="$t('products_agreements.dialog.child')"
                                            hide-details disabled
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--ZLATA ISTOTA-->
                    <v-col cols="12" v-if="product === 'productgoldt4'">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'target_price') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productTargetPrice) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t(path_table_key + 'fee')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.productAdvancePayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">
                                        {{ $t(path_table_key + 'monthly_payment') }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ currency }}
                                        {{ g_isDefined(agreement_data.product.monthlyPayment) }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-checkbox class="pt-0 mt-2"
                                            v-model="agreement_data.product.productMonthlyCharge"
                                            :label="$t(path_table_key + 'repayments')"
                                            hide-details disabled
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id') }}</v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(agreement_data.id) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.type')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{
                                            person_types.find(element => element.id === agreement_data.customer.type).name
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.title')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(agreement_data.customer.degree)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.name')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(agreement_data.customer.name)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.surname')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(agreement_data.customer.surname)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.private_email')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(agreement_data.customer.email)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.tel')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(agreement_data.customer.phone)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.date_of_birth')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_convertDate(agreement_data.customer.dateOfBirth)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id_number') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ agreement_data.customer.personalID }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--COMPANY DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.dic') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.taxId)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.company_name')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.companyName)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ico') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.companyId)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.dic') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.taxId)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ic_dph') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.vatId)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.street_and_number')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.mainAddress.street)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.city') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">
                                {{ g_isDefined(agreement_data.customer.mainAddress.city) }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.psc') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.mainAddress.zip)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.country') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(agreement_data.customer.mainAddress.country)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--AGREEMENT DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.agreement_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('products_agreements.dialog.signature_date')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_convertDate(agreement_data.signatureDate)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.iban') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">
                                {{ g_parseBankAccount(agreement_data.customer.bankAccount) }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">
                                {{ $t(path_table_key + 'deposit') }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">
                                {{ delivery_types.find(element => element.id === agreement_data.delivery).name }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--SELLER-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('products_agreements.dialog.seller') }}
                    </v-col>
                    <v-col cols="12">
                        <v-card flat outlined>
                            <v-card-text>
                            <span class="headline">
                                        {{ agreement_data.seller.name + " " + agreement_data.seller.surname }}
                                        <span class="gold--text" v-if="agreement_data.seller.companyName !== ''">
                                            - {{ agreement_data.seller.companyName }}
                                        </span>
                                    </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <v-dialog v-model="dialog_range" max-width="800" persistent>
            <v-card>
                <v-toolbar dense color="gold" dark>
                    <v-btn icon dark @click="dialog_range = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $t('products_agreements.dialog.monthly_report_title') }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <MonthlyStatementEmailDialog :id="this.id"
                                                 :options="{action: 'monthly_report', localize: 'products_agreements.dialog.monthly_report'}"
                                                 @close="closeDialog"/>
                </v-card-text>
            </v-card>

        </v-dialog>

    </section>
</template>

<script>
import DataSelect from "@/components/DataSelect";
import countries from "@/assets/countries";
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {
    agreementActivation, changeCustomerForAgreement,
    createAgreement,
    reallocateGrams,
    sendAgreementCertificateEmail,
    sendAgreementCustomerEmail,
    transferGoldPurchase,
    updateAgreement
} from "@/api/agreements";
import AdvancedMenu from "@/components/AdvancedMenu";
import Card from "@/components/Card";
import MonthlyStatementEmailDialog from "@/components/dialogs/MonthlyStatementEmailDialog.vue";
import {checkEmail} from "@/api/auth";
import {Utils} from "@/services/utils";

export default {
    name: "AgreementDialog",
    components: {
        DataSelect,
        AdvancedMenu,
        Card,
        MonthlyStatementEmailDialog
    },
    props: {
        edit: Boolean,
        type: String,
        id: undefined,
        card_item: undefined
    },
    mixins: [AuthMixin],

    watch: {
        modal_date_of_birth(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },

        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    data: () => ({
        dialog_select: false,
        dialog_transfer_grams: false,
        dialog_gold_purchase: false,
        dialog_agreement_confirm: false,
        dialog_range: false,

        info_text: null,

        path_dialog: 'products_agreements.dialog.',
        path_table_key: 'products_agreements.table_keys.',

        grid: {sm: 6, md: 4, lg: 2},
        agreementForm: false,
        button_menu: true,
        currency: "€",
        agreement_data: {},
        document_ready: false,
        user_type: undefined,
        select_list: undefined,

        product_combo: [
            {id: 0, alias: "5%", value: 5},
            {id: 1, alias: 'Prenesená', value: 'converted'},
            {id: 2, alias: 'Bonusová', value: 'bonus'},
            {id: 3, alias: 'Z balíčka', value: 'frompackage'}
        ],

        product_combo_t3: [
            {id: 5, alias: "5%", value: 5},
        ],

        product_t1_child: false,
        product_fee_combo: 0,

        person_types: [
            {id: 'PERSON', name: "Osoba"},
            {id: 'COMPANY', name: "Firma"},
            {id: 'SELF_EMPLOYED', name: "SZČO"}
        ],
        delivery_types: [
            {id: 'DEPOSIT', name: "Depozit"},
            {id: 'PICKUP', name: "Osobný odber"},
            {id: 'DELIVERY', name: "Dodanie zlata"},
        ],

        modal_date_of_birth: false,
        modal_start_collaboration: false,
        isPerson: true,

        col_default: {cols: 12, sm: 6, md: 4, lg: 3},
        col_email: {cols: 12, sm: 12, md: 6, lg: 6},
        col_grams: {cols: 12, sm: 6, md: 3, lg: 2},

        agreement_confirm: {},
        reallocate_grams: null
    }),

    computed: {
        canTransferGoldPurchases() {
            return this.checkPermission(Permissions.ADMINISTRATOR)
        },

        canSellProductT5ForFree() {
            return this.$store.getters.getSellerInfo.canSellProductT5ForFree || this.checkPermission(Permissions.ADMINISTRATOR || this.checkPermission(Permissions.SUPPORTER))
        },

        product_t5_child() {
            if (this.agreement_data.product.childName !== null && this.agreement_data.product.childName !== undefined) {
                return !!this.agreement_data.product.childName.length;
            } else {
                return false
            }
        },

        advancePayment() {
            let state = {};
            state.bonus = this.agreement_data.bonus;
            state.converted = this.agreement_data.converted;
            state.packageType = this.agreement_data.packageType;

            let keys = Object.keys(state);

            let filtered = keys.filter(function (key) {
                return state[key];
            });

            if (!filtered.length) {
                filtered.push(5);
            } else if (filtered[0] === 'packageType') {
                filtered = [];
                filtered.push('frompackage');
            }

            return filtered[0];
        },
        advancePaymentT3() {
            return Math.round(this.agreement_data.product.productAdvancePayment / this.agreement_data.product.productInvestmentTarget * 100)
        },
        getGoldPurchases() {
            return this.$store.getters.getGoldPurchases.items
        },
        getCurrentPriceList() {
            return this.$store.getters.getCurrentPriceList;
        },
        getProductSelection() {
            return this.agreement_data.product.productSelection;
        },
        users() {
            return this.$store.getters.getCustomers;
        },
        isDark() {
            return this.$vuetify.theme.dark;
        },
        breakPoint() {
            return this.$vuetify.breakpoint.name;
        },
        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.$t('products_agreements.action_buttons.' + this.agreement_data.product.productType.toLowerCase()) + " - " + this.id;
                }
            } else {
                return this.edit;
            }
        },
        getAgreementData() {
            return this.$store.getters.getAgreement;
        },
        getSelectedSeller() {
            return this.$store.getters.getSelectedSeller;
        },
        getSelectedUser() {
            return this.$store.getters.getSelectedUser;
        },
        product() {
            if (this.type) {
                return this.type;
            } else {
                return this.getAgreementData.product.productType.toLowerCase();
            }
        },
        userType() {
            return this.user_type;
        },
        selectList() {
            return this.select_list;
        },
        getCountries() {
            return countries.country_codes;
        },
        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                return this.g_getRouteName() === 'products_agreements';
            }

            return !this.edit;
        },

        disable() {
            return this.agreement_data.product.productType;
        },

        advancedMenu() {
            return [
                {
                    label: 'send_to_email', click() {
                        this.sendEmail()
                    }
                },
                {
                    label: 'send_certificate', click() {
                        this.sendCertificate()
                    }
                },
                {
                    disable_item: this.agreement_data.product.productType === 'ProductGoldT3',
                    label: 'send_monthly_report', click() {
                        this.sendMonthlyReport();
                    }
                },
                {
                    disable_item: !this.agreement_data.inactive,
                    label: 'activate', click() {
                        this.activation()
                    }
                },
                {
                    disable_item: this.agreement_data.inactive,
                    label: 'deactivate', click() {
                        this.activation()
                    }
                },
                {
                    disable_item: this.agreement_data.product.productType === 'ProductGoldT3' || !this.checkPermission(Permissions.ADMINISTRATOR),
                    label: 'transfer_grams', click() {
                        this.dialog_transfer_grams = true;
                    }
                },
                {
                    label: 'gold_purchase', click() {
                        this.dialog_gold_purchase = true;
                    }
                },
                {
                    label: 'change_customer', click() {
                        this.openSelectDialog('customers_list_select');
                    }
                }
            ]
        }
    },

    methods: {
        resetChildData() {
            this.agreement_data.product.childName = ""
            this.agreement_data.product.childPersonalId = ""
        },

        saveDateOfBirth() {
            this.$refs.menu_date_of_birth.save(this.agreement_data.customer.dateOfBirth)
            setTimeout(() => (this.$validator.validate('user_date_of_birth')))
        },

        closeDialog() {
            if (this.edit) {
                this.$parent.$emit('action', {func: 'close', data: null});
            } else {
                this.$parent.$emit('call', {func: 'close', data: null});
            }
        },

        updateProductComboT3() {
            if (this.edit || this.$store.getters.getSellerInfo.canUsePurchaseDiscount || this.checkPermission(Permissions.SUPPORTER || this.checkPermission(Permissions.ADMINISTRATOR))) {
                this.product_combo_t3 = [
                    {id: 0, alias: "0%", value: 0},
                    {id: 1, alias: "1%", value: 1},
                    {id: 2, alias: "2%", value: 2},
                    {id: 3, alias: "3%", value: 3},
                    {id: 4, alias: "4%", value: 4},
                    {id: 5, alias: "5%", value: 5}
                ]
            } else if (this.agreement_data.seller.id === this.$store.getters.getUserId && (this.$store.getters.getSellerInfo.packagePurchaseBonus !== null || this.$store.getters.getSellerInfo.packageType === 'Business')) {
                this.product_combo_t3 = [
                    {id: 0, alias: "0%", value: 0},
                    {id: 5, alias: "5%", value: 5}
                ]
            } else {
                this.product_combo_t3 = [
                    {id: 5, alias: "5%", value: 5}
                ]
                this.agreement_data.product.productFee = 5
            }
        },

        setPackageInfo() {

            this.product_combo = [
                {id: 0, alias: "5%", value: 5},
                {id: 1, alias: 'Prenesená', value: 'converted'},
                {id: 2, alias: 'Bonusová', value: 'bonus'},
            ]

            this.info_text = null
            if (this.agreement_data.seller.id === this.$store.getters.getUserId) {
                if (this.$store.getters.getSellerInfo.hasActivePackage) {
                    switch (this.product) {
                        case 'productgoldt1':
                        case 'productgoldt2':
                            this.info_text = this.$t('message_box.products_agreements_t1t2', [this.$store.getters.getSellerInfo.packageAgreementLimit])
                            this.product_combo.push({id: 3, alias: 'Z balíčka', value: 'frompackage'})
                            break;

                        case 'productgoldt3':
                            if (this.$store.getters.getSellerInfo.packagePurchaseBonus !== null) {
                                this.info_text = this.$t('message_box.products_agreements_t3', [this.$store.getters.getSellerInfo.packagePurchaseBonus])
                            }
                            break;
                    }
                }
            }
        },

        click_transferGoldPurchase() {
            this.$root.$emit('overlay', true);
            transferGoldPurchase(this.id, {
                targetAgreementId: this.agreement_confirm.transfer_agreement.id,
                goldPurchaseId: this.agreement_confirm.purchase.id
            }).then(() => {
                this.$root.$emit('overlay', false);
                this.closeDialog();
                this.$root.$emit('reload');
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'notification.transfer_gold_purchase'
                });
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.form_error'
                });
            })
        },

        click_reallocateGrams() {
            this.$root.$emit('overlay', true);
            this.$validator.validate('reallocate_grams').then(() => {

                if (!this.$validator.errors.any()) {
                    reallocateGrams(this.id, {value: this.reallocate_grams.replace(',', '.')}).then(() => {
                        this.$root.$emit('overlay', false);
                        this.closeDialog();
                        this.$root.$emit('reload');
                        this.$root.$emit('notification', {
                            type: 'success',
                            icon: 'update',
                            msg: 'notification.reallocate_grams'
                        });
                    }).catch(() => {
                        this.$root.$emit('overlay', false);
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'info',
                            msg: 'notification.form_error'
                        });
                    })
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        increase(_index) {
            this.agreement_data.product.productSelection[_index].count++;
            this.update_t3();
            this.$nextTick(() => {
                this.$validator.validate('product_selection');
            });
        },
        decrease(_index) {
            this.agreement_data.product.productSelection[_index].count--;
            this.update_t3();
            this.$nextTick(() => {
                this.$validator.validate('product_selection');
            });
        },

        advancedPaymentForm() {
            this.agreement_data.product.productAdvancePayment = 0;
            if (this.agreement_data.product.productFee === 0) {
                let value = parseFloat(this.agreement_data.product.productTargetPrice * 0.05)
                if (!isNaN(value)) {
                    this.agreement_data.product.productAdvancePayment = value.toFixed(2)
                }
            }

            this.$validator.validate("product_target_price")

            this.$forceUpdate();
        },

        calcT3() {
            let sum = [];
            let result = {};
            let list = this.agreement_data.product.productSelection;

            for (let item of list) {
                sum.push(parseFloat(parseFloat(item.count * item.price).toFixed(2)));
            }

            result.productInvestmentTarget = parseFloat(sum.reduce((a, b) => a + b).toFixed(2));
            result.productFee = this.agreement_data.product.productFee;
            result.productAdvancePayment = parseFloat(parseFloat((result.productInvestmentTarget * result.productFee) / 100).toFixed(2));
            result.productTargetPrice = parseFloat((parseFloat(result.productInvestmentTarget) + parseFloat(result.productAdvancePayment)).toFixed(2));

            return result;
        },
        update_t3() {
            let product = this.calcT3();
            this.agreement_data.product.productInvestmentTarget = parseFloat(product.productInvestmentTarget).toFixed(2);
            this.agreement_data.product.productFee = product.productFee;
            this.agreement_data.product.productAdvancePayment = parseFloat(product.productAdvancePayment).toFixed(2);
            this.agreement_data.product.productTargetPrice = parseFloat(product.productTargetPrice).toFixed(2);

            this.$validator.validate("product_selection")

            this.$forceUpdate();
        },

        refresh() {
            this.$forceUpdate();
        },

        validatorRefresh() {
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },

        submit(e) {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    this.save(e);
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        save() {
            this.$root.$emit('overlay', true);

            let customerForm = {
                email: this.agreement_data.customer.email,

                name: this.agreement_data.customer.name,
                surname: this.agreement_data.customer.surname,
                degree: this.agreement_data.customer.degree,

                dateOfBirth: this.agreement_data.customer.dateOfBirth,
                personalID: this.agreement_data.customer.personalID,

                type: this.agreement_data.customer.type,

                companyId: this.agreement_data.customer.companyId,
                companyName: this.agreement_data.customer.companyName,
                taxId: this.agreement_data.customer.taxId,
                vatId: this.agreement_data.customer.vatId,

                phone: this.agreement_data.customer.phone,

                mainAddress: {
                    street: this.agreement_data.customer.mainAddress.street,
                    city: this.agreement_data.customer.mainAddress.city,
                    zip: this.agreement_data.customer.mainAddress.zip,
                    country: this.agreement_data.customer.mainAddress.country
                },

                bankAccount: this.agreement_data.customer.bankAccount,
            }

            if (this.agreement_data.customer.type === 'PERSON') {
                customerForm.companyId = ''
                customerForm.companyName = ''
                customerForm.vatId = ''
            }

            let productForm = {
                type: "",
                productTargetPrice: this.agreement_data.product.productTargetPrice,
                productAdvancePayment: this.agreement_data.product.productAdvancePayment,
                childName: "",
                childPersonalId: "",
                monthlyPayment: 0,
                productSelectionForm: {}
            }

            let agreementForm = {
                sellerId: this.agreement_data.seller.id,
                customerId: this.agreement_data.customer.id,
                customerForm: customerForm,
                productForm: productForm,
                bonus: false,
                converted: false,
                forFree: false,
                fromPackage: false,
                delivery: this.agreement_data.delivery,
                signatureDate: this.agreement_data.signatureDate
            }

            if (this.product === 'productgoldt1') {
                productForm.type = "PRODUCT_GOLD_T1"
                productForm.monthlyPayment = this.agreement_data.product.monthlyPayment
                productForm.childName = this.agreement_data.product.childName
                productForm.childPersonalId = this.agreement_data.product.childPersonalId
            } else if (this.product === 'productgoldt2') {
                productForm.type = "PRODUCT_GOLD_T2"
                productForm.monthlyPayment = this.agreement_data.product.monthlyPayment
            } else if (this.product === 'productgoldt3') {
                productForm.type = "PRODUCT_GOLD_T3"
                productForm.productTargetPrice = this.agreement_data.product.productInvestmentTarget
                productForm.productSelectionForm = {
                    bar1g: this.agreement_data.product.productSelection[0].count,
                    bar2g: this.agreement_data.product.productSelection[1].count,
                    bar5g: this.agreement_data.product.productSelection[2].count,
                    bar10g: this.agreement_data.product.productSelection[3].count,
                    bar20g: this.agreement_data.product.productSelection[4].count,
                    bar50g: this.agreement_data.product.productSelection[5].count,
                    bar100g: this.agreement_data.product.productSelection[6].count,
                    bar250g: this.agreement_data.product.productSelection[7].count,
                    bar500g: this.agreement_data.product.productSelection[8].count,
                    bar1000g: this.agreement_data.product.productSelection[9].count,
                    coinOneOunce: this.agreement_data.product.productSelection[10].count,
                }
            } else if (this.product === 'productgoldt5') {
                productForm.type = "PRODUCT_GOLD_T5"
                productForm.monthlyPayment = this.agreement_data.product.monthlyPayment
                productForm.childName = this.agreement_data.product.childName
                productForm.childPersonalId = this.agreement_data.product.childPersonalId
                agreementForm.forFree = this.agreement_data.forFree
            }

            if (this.product === 'productgoldt1' || this.product === 'productgoldt2') {
                if (this.agreement_data.product.productFee === 2) {
                    agreementForm.bonus = true
                } else if (this.agreement_data.product.productFee === 1) {
                    agreementForm.converted = true
                } else if (this.agreement_data.product.productFee === 3) {
                    agreementForm.fromPackage = true
                }
            }

            if (this.edit) {
                updateAgreement(this.agreement_data.id, agreementForm).then(() => {
                    this.$root.$emit('overlay', false);
                    this.closeDialog();
                    this.$root.$emit('reload');
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'products_agreements.update_notify'
                    })
                })
            } else {
                createAgreement(agreementForm).then(() => {
                    this.$root.$emit('overlay', false);
                    this.closeDialog();
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'products_agreements.create_notify'
                    })
                    this.$store.dispatch("getSellerInfo")
                })
            }
        },

        handleClick(index) {
            this.advancedMenu[index].click.call(this);
        },

        sendEmail() {
            this.$root.$emit('overlay', true);
            sendAgreementCustomerEmail(this.agreement_data.id).then(() => {
                this.$root.$emit('overlay', false);
                this.closeDialog();
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'products.send_email_notify'
                });
            })
        },

        sendCertificate() {
            this.$root.$emit('overlay', true);
            sendAgreementCertificateEmail(this.agreement_data.id).then(() => {
                this.$root.$emit('overlay', false);
                this.closeDialog();
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'products.send_certificate_notify'
                });
            })
        },

        activation() {
            this.$root.$emit('overlay', true);
            agreementActivation(this.agreement_data.id).then(() => {
                this.$root.$emit('overlay', false);
                this.agreement_data.inactive = !this.agreement_data.inactive
                this.closeDialog();
                this.$root.$emit('reload');
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: this.agreement_data.inactive ? 'products.deactivate_notify' : 'products.activate_notify'
                });
            })
        },

        sendMonthlyReport() {
            this.dialog_range = true;
        },

        callback(e) {
            this.$parent.$emit('action', {func: e, data: this.id});
        },

        callbackFromCreateMode(_action) {
            this.$parent.$emit('call', {func: _action, data: this.agreement_data});
        },

        newCustomer() {
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getSeller', this.$store.getters.getUserId)
                .then(() => {
                    Object.assign(this.agreement_data.seller, this.getSelectedSeller);
                    this.setPackageInfo()
                    this.updateProductComboT3()
                    this.agreement_data.delivery = this.delivery_types[0].id;
                    this.agreement_data.customer.id = null
                    this.$forceUpdate();
                    this.$root.$emit('overlay', false);
                    this.agreementForm = true;
                    this.button_menu = false;
                }).catch()
        },

        openSelectDialog(_type, _gold_purchase) {
            this.agreement_confirm.purchase = _gold_purchase;
            if (_type === 'customers_list_select') {
                this.$store.dispatch('getSeller', this.$store.getters.getUserId)
                    .then(() => {
                        Object.assign(this.agreement_data.seller, this.getSelectedSeller);
                        this.setPackageInfo()
                        this.updateProductComboT3()
                        this.agreement_data.delivery = this.delivery_types[0].id;

                        this.$forceUpdate();
                    }).catch()
            }

            this.select_list = _type;
            this.dialog_select = true;
        },

        selectSeller(_id) {
            this.$store.dispatch('getSeller', _id)
                .then(() => {
                    this.agreement_data.seller = this.getSelectedSeller;
                    this.setPackageInfo()
                    this.updateProductComboT3()
                    this.$root.$emit('overlay', false);
                    this.dialog_select = false;
                }).catch()
        },

        selectCustomer(_id) {
            this.$store.dispatch('getCustomer', _id)
                .then(() => {
                    this.agreement_data.customer = this.getSelectedUser;
                    this.$root.$emit('overlay', false);
                    this.agreementForm = true;
                    this.button_menu = false;
                    this.dialog_select = false;
                }).catch()
        },

        changeCustomer(_id) {
            this.$root.$emit('overlay', true);
            changeCustomerForAgreement(this.agreement_data.id, {userId: _id}).then(() => {
                this.$root.$emit('overlay', false);
                this.closeDialog();
                this.$root.$emit('reload');
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'products.customer_change_notify'
                });
            })
        },

        reset() {
            this.button_menu = false;
            this.agreementForm = false;
            this.agreement_data = {};
            this.document_ready = false;
        },

        checkType() {
            this.isPerson = this.agreement_data.customer.type === 'PERSON';
            this.validatorRefresh();
        },

        createReady() {
            this.document_ready = true;
            this.button_menu = true;
            this.agreementForm = false;
        },

        productT5ForFree(_for_free) {
            _for_free ? this.agreement_data.product.productAdvancePayment = 0 : this.agreement_data.product.productAdvancePayment = 60;
        }
    },

    created() {
        this.$validator.extend('checkExistingEmail', {
            validate: (value) => {
                if (this.agreement_data.customer.id !== null) {
                    return true
                }

                return checkEmail(value)
                    .then(response => {
                        return !response.data;
                    })
            }
        });

        this.$validator.extend('bornLimit', {
            validate: (value) => {
                return Utils.is18YearsOld(value)
            }
        });

        this.$validator.extend('max_gold', {
            validate: (value) => {
                return parseFloat(value) <= parseFloat(this.card_item.raw_data.grams_total);
            }
        });

        this.$validator.extend('child_born_limit', {
            validate: (value) => {
                if (value.length >= 6) {

                    let newValue = value;

                    if (newValue[2] === '5') {
                        newValue = newValue.substring(0, 2) + '0' + newValue.substring(3)
                    } else if (newValue[2] === '6') {
                        newValue = newValue.substring(0, 2) + '1' + newValue.substring(3)
                    }

                    let year = newValue.substring(0, 2)
                    let month = newValue.substring(2, 4)
                    let day = newValue.substring(4, 6)

                    if (isNaN(year) || isNaN(month) || isNaN(day)) {
                        return false
                    }

                    let dateOfBirth = '20' + year + "-" + month + "-" + day

                    let getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
                    let age = getAge(dateOfBirth)
                    return age >= 0 && age < 18
                }

                return false
            }
        });

        this.$validator.extend('t3', {
            validate: (value) => {
                return value !== 0;
            }
        });

        this.$validator.extend('package_t1t2', {
            validate: (value) => {
                if (this.$store.getters.getSellerInfo.hasActivePackage) {
                    if (this.product_combo[this.agreement_data.product.productFee].value === 'frompackage') {
                        let limit = parseFloat(this.$store.getters.getSellerInfo.packageAgreementLimit)
                        return parseFloat(value) <= limit
                    }
                }

                return true
            }
        });

        this.$validator.extend('package_t3', {
            validate: (value) => {
                if (this.$store.getters.getSellerInfo.hasActivePackage) {
                    if (this.$store.getters.getSellerInfo.packageType === 'Gold') {
                        if (this.agreement_data.product.productFee === 0) {
                            let limit = parseFloat(this.$store.getters.getSellerInfo.packagePurchaseBonus)
                            return parseFloat(value) <= limit
                        }
                    }
                }

                return true
            }
        });

        this.$validator.extend('double', {
            validate: (value) => {
                return (/^[0-9]*[.,]?[0-9]{0,2}$/g.test(value));
            }
        });

        this.agreement_data.customer = {};
        this.agreement_data.customer.mainAddress = {};

        this.agreement_data.customer.type = 'PERSON';
        this.agreement_data.customer.phone = '+421';
        this.agreement_data.customer.mainAddress.country = 'Slovensko'
        this.agreement_data.signatureDate = this.g_getDate();
        this.agreement_data.seller = {};
        this.agreement_data.product = {};
        this.agreement_data.product.productFee = {};
        this.agreement_data.product.productSelection = [];

        this.validatorRefresh();

    },

    mounted() {
        this.updateProductComboT3()

        if (this.edit) {
            this.$root.$emit('overlay', true);
            this.$store.dispatch("getGoldPurchases", this.id)
            this.$store.dispatch('getAgreement', this.id)
                .then(() => {
                    this.agreement_data = this.getAgreementData
                    switch (this.product) {
                        case 'productgoldt1':
                        case 'productgoldt2':
                            this.agreement_data.product.productFee = this.product_combo.find(element => element.value === this.advancePayment).id;
                            break;

                        case 'productgoldt3':
                            this.agreement_data.product.productInvestmentTarget = parseFloat(this.agreement_data.product.productTargetPrice).toFixed(2)
                            this.agreement_data.product.productTargetPrice = parseFloat(parseFloat(this.agreement_data.product.productTargetPrice) + parseFloat(this.agreement_data.product.productAdvancePayment)).toFixed(2)
                            this.agreement_data.product.productAdvancePayment = parseFloat(this.agreement_data.product.productAdvancePayment).toFixed(2)
                            this.agreement_data.product.productFee = this.advancePaymentT3
                            break;

                        case 'productgoldt5':
                            this.product_t1_child = this.product_t5_child
                            break
                    }

                    this.checkType();
                    this.document_ready = true;
                    this.button_menu = false;
                    this.agreementForm = true;
                    this.$root.$emit('overlay', false);
                }).catch();
        } else switch (this.product) {
            /*ZLATA RYBKA*/
            case 'productgoldt1':
                this.agreement_data.product.productFee = this.product_combo[0].id;
                this.agreement_data.product.monthlyPayment = 50;
                this.createReady();
                break;

            /*ZLATY DOCHODOK*/
            case 'productgoldt2':
                this.agreement_data.product.productFee = this.product_combo[0].id;
                this.agreement_data.product.monthlyPayment = 50;
                this.createReady();
                break;

            /*ZLATY POKLAD*/
            case 'productgoldt3':
                this.$store.dispatch('getCurrentPriceList')
                    .then(() => {
                        let currentPrices = this.getCurrentPriceList;
                        let count = 0;
                        for (let key in currentPrices) {
                            if (count > 3) {
                                this.agreement_data.product.productSelection.push(
                                    {count: 0, name: this.$t('products.' + key), price: currentPrices[key]}
                                );
                            }
                            count++;
                        }
                        this.agreement_data.product.productFee = 5;

                        this.createReady();
                    });
                break;

            /*ZLATA ISTOTA*/
            case 'productgoldt4':
                this.createReady();
                break;

            /*ZLATA MINCA*/
            case 'productgoldt5':
                this.agreement_data.product.productTargetPrice = 6000
                this.agreement_data.product.monthlyPayment = 50;
                this.agreement_data.product.productAdvancePayment = 60;
                this.createReady();
                break;
        }

        if (this.checkPrivileg) {
            this.$root.$on('item-seller', (id) => {
                this.selectSeller(id);
            });
        }

        this.$root.$on('item-user', (id) => {
            if (this.edit) {
                this.changeCustomer(id)
            } else {
                this.selectCustomer(id);
            }
        });

        this.$on('call', (data) => {
            if (data.func === 'close') {
                this.closeDialog()
            }
        });

        this.$root.$on('item-gold-transfer', (id) => {
            this.agreement_confirm.id = id;
            this.$store.dispatch('getTransferAgreement', id)
                .then(() => {
                    this.agreement_confirm.transfer_agreement = this.$store.getters.getTransferAgreement;
                    this.dialog_agreement_confirm = true;
                })

        });
    },

    destroyed() {
        this.$store.dispatch('clearAgreement');
        if (this.checkPrivileg) {
            this.$root.$off('item-seller');
            this.$root.$off('item-seller');
        }
        this.$root.$off('item-user');
        this.$root.$off('item-customer');
        this.$root.$off('item-gold-transfer');
        this.$root.$off('item-gold-transfer');
        this.$root.$off('call');
        this.reset();
    },
    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    }
}
</script>

<style>
.v-input.gram input {
    text-align: center;
}

[role="alert"] .v-messages__message {
    color: red;
}
</style>